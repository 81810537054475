<template>
    <div>
      <design-status-list></design-status-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  